.print {
  .recipe {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
  }

  h1,
  h2 {
    color: navy;
  }

  .ingredients {
    list-style-type: none;
  }

  .ingredients li,
  .steps li {
    margin-bottom: 10px;
  }

  /* Styling for checkboxes */
  input[type="checkbox"] {
    margin-right: 10px;
    cursor: pointer;
    /* Making the checkbox larger */
    height: 40px;
    width: 40px;
    /* Optional: custom checkbox appearance */
    appearance: none;
    background-color: #fff;
    border: 2px solid #333;
    transition: background 0.3s;

    /* Custom checkmark using Unicode */
    display: inline-block;
    text-align: center;
    vertical-align: middle;
  }

  /* Print specific styles */
  @media print {
    body {
      background-color: white;
      color: black;
    }
    a {
      display: none;
    }

    .recipe {
      page-break-after: always;
    }

    h2 {
      page-break-before: always;
      page-break-after: avoid;
    }

    .ingredients,
    .steps {
      page-break-inside: avoid;
    }

    .ingredients li,
    .steps li {
      page-break-inside: avoid;
    }

    /* Ensuring checkboxes are visible when printed */
    input[type="checkbox"] {
      print-color-adjust: exact;
      color-adjust: exact; /* Ensures that the colors and styles are printed accurately */
    }

    /* Prevents extra space or elements from forcing a new page */
    :last-child {
      page-break-after: avoid;
    }
  }
}
